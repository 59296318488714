<template>
  <div class="content">
    <div class="main">
      <div class="top">
        <div class="logo">
          <v-img :src="require('../../../image/top_title.png')"></v-img>
        </div>
      </div>
      <div class="search">
        <div class="search-box">
          <el-input
              size="large"
              placeholder="请输入条款关键字..."
              v-model="vagueName"
              class="input-with-select"
          >
            <el-button
                slot="append"
                style="
                background-color: #4178b5;
                color: white;
                font-size: 20px;
                padding: 17px 24px;
              "
                icon="el-icon-search"
                @click="onSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <div class="clause-list">
        <div class="clause-top">
          <div class="title">
            <v-img :src="require('../../../img/msjx.png')" width="113"></v-img>
          </div>
          <div class="filter-result-right">
            <div @click="onPublishClick()" class="fr-right-left">
              印发时间
              <el-image
                  style="vertical-align: middle; margin-left: 5px"
                  :src="
                    require(search.sort == 'asc'
                      ? '../../../img/asc.png'
                      : '../../../img/desc.png')
                  "
              ></el-image>
            </div>
            <div @click="onVolumeClick()" class="fr-right-center">
              浏览量
              <el-image
                  style="vertical-align: middle; margin-left: 5px"
                  :src="
                    require(search.volume == 'asc'
                      ? '../../../img/asc.png'
                      : '../../../img/desc.png')
                  "
              ></el-image>
            </div>
            <div class="fr-right-right">
              <el-checkbox
                  v-model="search.isValid"
                  label="仅显示有效条款"
              ></el-checkbox>
            </div>
          </div>
        </div>
        <!--      <div class="policy-item" @click="onItemClick(item.id)" :key="item.id" v-for="item in clauseList">
                <div class="item-left">
                  <div class="title van-multi-ellipsis&#45;&#45;l2">
                    {{ item.clauseName }}
                  </div>
                  <div class="tag van-ellipsis">
                    <el-tag
                        color="#E7EEF6"
                        text-color="#1156A3"
                        size="large"
                        v-for="m in item.policyType ? item.policyType.split(','):[]"
                        :key="m"
                    >{{ m }}
                    </el-tag
                    >
                  </div>
                  <div class="info">
                    <div class="info-left">
                      <el-image :src="require('../../../img/dept.png')"></el-image>
                      {{ item.deptName }}
                    </div>
                    <div class="info-center">
                      <el-image :src="require('../../../img/addr.png')"></el-image>
                      {{ item.address }}
                    </div>
                    <div class="info-right">
                      <el-image :src="require('../../../img/date.png')"></el-image>
                      {{ item.printedTime }}
                    </div>
                    <div class="info-right">
                      <el-image :src="require('../../../img/seed.png')"></el-image>
                      {{ item.browseNum }}
                    </div>
                  </div>
                </div>
                <div class="item-right">
                  <div class="time">
                    <div v-if="item.isValid == 1">
                      <div>条款有效期</div>
                      <div>长期有效</div>
                    </div>
                    <div v-if="item.isValid == 0">
                      <div>条款有效期</div>
                      <div>至 {{ item.validEndTime }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <el-pagination
                  @size-change="sizeChangeHandle"
                  @current-change="currentChangeHandle"
                  :current-page="pageIndex"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  :total="totalCount"
                  layout="total, sizes, prev, pager, next, jumper">
              </el-pagination>-->
        <policy_list :get-clause-list="getClauseList" :get-list-key="getListKey"></policy_list>
      </div>
    </div>
    <suspension_btn/>
  </div>
</template>

<script>
import policy_list from "@/views/index/home/compoents/policy_list";

export default {
  components: {policy_list},
  data() {
    return {
      getListKey: 0,
      search: {
        cashType: '免申即享',
        key: null,
        sort: 'desc',
        volume: 'desc',
        isValid: false,
        page: 1,
        limit: 10
      },
      vagueName: "",
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      clauseList: [],
    };
  },
  watch: {
    'search': {
      handler: async function (v) {
        this.viewData.search = Object.assign(this.viewData.search, v);
        //this.getListKey++;
      },
      deep: true
    },
    'search.isValid':async function(){
      this.getListKey++;
    },
    deep:true
  },
  methods: {
    onSearch() {
      // console.log(this.viewData.search)
      this.search.key = this.vagueName;
      this.getListKey++;
    },
    onPublishClick() {
      if (this.search.sort == "asc") {
        this.search.sort = "desc";
      } else {
        this.search.sort = "asc";
      }
      this.getListKey++;
      //this.getClauseList(1)
    },
    onVolumeClick() {
      if (this.search.volume == "asc") {
        this.search.volume = "desc";
      } else {
        this.search.volume = "asc";
      }
      this.getListKey++;
      //this.getClauseList(1)
    },
    onItemClick(id) {
      this.goToView('content/detail', {id});
    },
    getClauseList(page) {
      return new Promise(resolve => {
        if (page) this.search.page = page
        this.search.limit = this.pageSize
        this.$http({
          url: this.$http.adornUrl('/app/huiqiclause/list'),
          method: 'post',
          data: this.$http.adornData(this.search)
        }).then((data) => {
          if (data && data.code === 0) resolve(data.page)
          else resolve(false)
        })
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  background: #f0f4f8 url("../../../image/top_bg.png") no-repeat;
}

.main {
  width: 1300px;
  margin: 0 auto;

  .top {
    padding-top: 96px;
    padding-bottom: 70px;

    .logo {
      width: 474px;
      margin: 0 auto;
    }
  }

  .search {
    .search-box {
      background-color: white;
      width: 900px;
      margin: 0 auto;
      border-radius: 4px;
      overflow: hidden;
      padding: 1px 0 1px 1px;

      ::v-deep .el-input__inner {
        height: 54px;
        line-height: 54px;
      }

      ::v-deep .el-input__inner::placeholder {
        font-size: 18px;
        color: #aaaaaa;
      }
    }
  }

  .clause-top {
    display: flex;
    margin-bottom: 20px;

    .title {
      flex-grow: 1;
    }

    .filter-result-right {
      flex-shrink: 0;
      display: flex;
      color: #888888;
      line-height: 1;

      .fr-right-left {
        margin: 0 5px;
        padding-top: 5px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .fr-right-center {
        margin: 0 5px;
        padding-top: 5px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }

      .fr-right-right {
        padding-top: 3px;
        margin: 0 5px 0 20px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .clause-list {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    margin-top: 150px;

    .clause-title {
      margin-bottom: 15px;
    }

    .policy-item {
      padding: 10px 0 20px;
      display: flex;
      border-bottom: solid 1px #e5e5e5;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      .item-left {
        flex-grow: 1;
        padding-right: 20px;
        line-height: 1.5;
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 0;

        .title {
          font-size: 26px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .tag {
          margin-top: 10px;
          width: 100%;
          overflow: hidden;

          .el-tag {
            margin-right: 10px;
            display: inline-block;
          }
        }

        .info {
          display: flex;
          font-size: 16px;
          color: #888;
          margin-top: 10px;

          .el-image {
            vertical-align: middle;
          }

          .info-center {
            margin-left: 30px;
            max-width: 600px;
          }

          .info-right {
            text-align: left;
            margin-left: 30px;
          }
        }
      }

      .item-right {
        width: 200px;
        background: url("../../../image/list_yxq_bg.png");
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-grow: 0;
        flex-shrink: 0;
        flex-direction: column;

        .time {
          font-size: 20px;
          color: white;
          padding: 20px;
          text-align: center;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          line-height: 1.5;
        }

        .tip {
          font-size: 24px;
          color: #eee;
          background-color: #4178b5;
          text-align: center;
          padding: 12px 0;
        }
      }
    }
  }
}
</style>